html {
  font-size: 16px;
  line-height: 1.571;
  -webkit-font-smoothing: antialiased;
  font-feature-settings: "liga", "dlig";
  font-feature-settings: "frac" 1;
  font-feature-settings: "smcp" 1;
}

body {
  font-family: "Open Sans", Verdana, Geneva, "DejaVu Sans", Arial, Helvetica, sans-serif;
  font-weight: 300;
  text-rendering: optimizeLegibility;
  font-feature-settings: "kern";
  font-kerning: normal;
}

.text-center {
  text-align: center;
}

h1,
h2,
h3,
h4 {
  color: #222;
}

h1 a,
h2 a,
h3 a,
h4 a {
  color: #222;
  text-decoration: none;
}

h1 {
  font-size: 23px;
  font-family: "Open Sans", Verdana, Geneva, "DejaVu Sans", Arial, Helvetica, sans-serif;
  line-height: 1.1em;
  font-weight: 700;
  margin: 0 0 0.67em;
  hyphens: none;
}
@media (min-width: 768px) {
  h1 {
    font-size: 28px;
  }
}
h1 a {
  color: #017630;
}

h2,
h2.field-label {
  font-size: 22px;
  font-family: "Open Sans", Verdana, Geneva, "DejaVu Sans", Arial, Helvetica, sans-serif;
  line-height: 1.1em;
  font-weight: 700;
  margin: 0.83em 0 0.5em;
  hyphens: none;
}

h3,
h3.field-label {
  font-size: 20px;
  font-family: "Open Sans", Verdana, Geneva, "DejaVu Sans", Arial, Helvetica, sans-serif;
  line-height: 1.35;
  font-weight: 700;
  margin: 1em 0 0.5em;
}

h4 {
  font-size: 16px;
  font-family: "Open Sans", Verdana, Geneva, "DejaVu Sans", Arial, Helvetica, sans-serif;
  line-height: 1.35;
  font-weight: 600;
  margin: 1.33em 0 0.5em;
}

h5 {
  font-size: 0.83em;
  font-family: "Open Sans", Verdana, Geneva, "DejaVu Sans", Arial, Helvetica, sans-serif;
  line-height: 1.35;
  font-weight: 600;
  margin: 1.67em 0 0.5em;
}

h6 {
  font-size: 0.75em;
  font-family: "Open Sans", Verdana, Geneva, "DejaVu Sans", Arial, Helvetica, sans-serif;
  line-height: 1.35;
  font-weight: 600;
  margin: 2.33em 0;
}

#page-title {
  margin-top: 0;
}

p {
  margin: 0 0 0.9426em;
}
p:empty {
  display: none;
}

pre,
code,
tt,
samp,
kbd,
var {
  font-family: Consolas, Monaco, "Courier New", Courier, monospace, sans-serif;
}

b,
strong {
  font-weight: 600;
}

small {
  font-size: 12px;
}

pre {
  margin: 0 0 1.5em;
}

hr {
  height: 0;
  clear: both;
  border-width: 1px 0 0;
  border-style: solid;
  border-color: #cccccc;
  margin-top: 1.75rem;
  margin-bottom: 1.75rem;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

::selection {
  background-color: #017630;
  color: #fff;
}

.text-center {
  text-align: center;
}