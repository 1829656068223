
@import "base";

// =============================================================================
//  Global
// =============================================================================

// Based on: http://t3n.de/news/opentype-font-feature-setting-531985/?utm_content=buffer356b0&utm_medium=social&utm_source=twitter.com&utm_campaign=buffer
html{
  font-size: $font-size-px;
  line-height: $line-height;

  // Mac OS Bugfix (font showing up way to bold)
  -webkit-font-smoothing: antialiased;

  // Ligaturen aktivieren
  font-feature-settings: "liga", "dlig";

  // Use OpenType Breaks
  font-feature-settings: "frac" 1;

  // Use real small caps
  font-feature-settings: "smcp" 1;
}

body {
  font-family: $font-first;
  font-weight: $font-weight-normal;
  text-rendering: optimizeLegibility;
  font-feature-settings: "kern";
  font-kerning: normal;

  // Silbentrennung
  // @include hyphens(auto);
}

// =============================================================================
//  Helper
// =============================================================================

.text-center{
  text-align:center;
}

// =============================================================================
//  Headlines
// =============================================================================

h1,
h2,
h3,
h4 {
  color:$title-color;
}

h1 a,
h2 a,
h3 a,
h4 a{ color:$title-color; text-decoration:none; }

h1 {
  font-size: $h1-font-size - 5px;
  font-family: $h1-font-family;
  line-height:$h1-line-height;
  font-weight:$h1-font-weight;
  margin: 0 0 .67em;
  hyphens: none;
  @include media-breakpoint-up(md){
    font-size: $h1-font-size;
  }
  a{
    color:$highlight;
  }
}


h2,
h2.field-label {
  font-size: $h2-font-size;
  font-family: $h2-font-family;
  line-height:$h2-line-height;
  font-weight:$h2-font-weight;
  margin: .83em 0 .5em;
  hyphens: none;
}
h3,
h3.field-label {
  font-size: $h3-font-size;
  font-family: $h3-font-family;
  line-height:$h3-line-height;
  font-weight:$h3-font-weight;
  margin: 1em 0 .5em;
}
h4 {
  font-size: $h4-font-size;
  font-family: $h4-font-family;
  line-height:$h4-line-height;
  font-weight:$h4-font-weight;
  margin: 1.33em 0 .5em;
}
h5 {
  font-size: $h5-font-size;
  font-family: $h5-font-family;
  line-height:$h5-line-height;
  font-weight:$h5-font-weight;
  margin: 1.67em 0 .5em;
}
h6 {
  font-size: $h6-font-size;
  font-family: $h6-font-family;
  line-height:$h6-line-height;
  font-weight:$h6-font-weight;
  margin: 2.33em 0;
}

#page-title {
  margin-top: 0;
}

// =============================================================================
//  Content Elements
// =============================================================================
p{
  margin:0 0 ($paragraph-spacing + em);
  &:empty{
    display:none;
  }
}

pre,
code,
tt,
samp,
kbd,
var {
  font-family: $font-code;
}

b,
strong {
  font-weight: $font-weight-bold;
}

small {
  font-size: $font-small;
}

pre {
  margin: 0 0 1.5em;
}

hr{
  height:0;
  clear:both;
  border-width:1px 0 0;
  border-style:solid;
  border-color: $cutline-color;
  margin-top:1.75rem;
  margin-bottom:1.75rem;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -.5em;
}


sub {
  bottom: -.25em;
}

// Selection
::selection {
  background-color: $selection-color;
  color:$selection-color-foreground;
}

// =============================================================================
//  Text formatters
// =============================================================================

.text-center{
  text-align:center;
}
